<template>
  <div>
    <div style="margin: 10vh auto 0px; text-align: center">
      <van-image width="100" height="100" src="https://hwl-release-oss.oss-cn-zhangjiakou.aliyuncs.com/files/20211106/2021/11/06/1636186962062.png" />
      <div style="margin-top: 20px;font-size: 25px">绑定成功</div>
    </div>

  </div>
</template>

<script>
import {Icon, Toast, Image as VanImage  } from 'vant';
export default {
name: "go",
  components: {
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
  },
  methods: {
    closePage() {
      window.close()
    }
  }
}
</script>

<style scoped>
  .go-box{
    color: springgreen;
  }
</style>
